<template>
  <div class="text-left">
    <div
      class="card text-center"
    >
      <router-link :to="{ name: 'Event', params: { slug: event.slug } }">
        <ResponsiveImage :image="event.metadata.main_image.imgix_url"></ResponsiveImage>
<!--        <img-->
<!--          v-lazy="-->
<!--            event.metadata.main_image.imgix_url +-->
<!--            '?h=100&w=400&q=&fit=clamp&h=160'-->
<!--          "-->
<!--          style="-->
<!--            z-index: 12;-->
<!--            box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);-->
<!--            margin: 10px;-->
<!--          "-->
<!--          v-bind:alt="event.title"-->
<!--          height="100px"-->
<!--        />-->
        <br />
        <h2
          class="tulum-party-title"
          style="
            margin-top: 30px;
            margin-bottom: 2px;
            font-size: 0.8rem;
            text-transform: uppercase;
          "
        >
          {{ event.title }}
        </h2>
        <h4
          class="tulum-party-subtitle"
          style="text-transform: uppercase; font-size: 0.5rem"
        >
          <span style="color: aquamarine">></span>
          {{
            (event.metadata.date + ' ' + event.metadata.start_time)
              | formattedDate
          }}
        </h4>
        <br />

        <a href="#" class="btn btn-sm btn-call-for-action">VIEW</a>
      </router-link>
      <hr />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import ResponsiveImage from '@/components/HeadImage/ResponsiveImage';
export default {
  name: 'CondensedEventCard',
  components: { ResponsiveImage },
  props: ['event', 'idx', 'condensed'],
  filters: {
    formattedDate: function attachPrettyDate(start_time) {
      const partyDate = start_time;
      const format = 'dddd, MMMM Do YYYY';
      const formatOptions = {
        sameDay: function () {
          return moment(`${partyDate}`, 'YYYY-MM-DD HH:mm', true).isValid()
            ? `[${this.calendar()}] `
            : `[Today ~] ${format}`;
        },
        nextDay: function () {
          return moment(`${partyDate}`, 'YYYY-MM-DD HH:mm', true).isValid()
            ? `[${this.calendar()} / ] ${format}`
            : `[Tomorrow ~] ${format}`;
        },
        nextWeek: format,
        sameElse: format,
      };

      return moment(partyDate).calendar(null, formatOptions);
    },
  },

  methods: {

  },
};
</script>

<style scoped></style>
